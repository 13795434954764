import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card as CardComponent } from 'theme-ui'
import { Heading, Text } from 'theme-ui'
const styles = {
  body: {
    fontWeight: `body`,
    color: `omegaDark`
  },
  link: {
    color: `alphaDark`
  }
}


const PageAbout = props => {

  return (
    <Layout {...props}>
      <Seo title='Om os' />
      <Divider />
      <Stack>
        <Main>
          <CardComponent variant='paper-lg'>
            <PageTitle
              header="Om os"
            />

            <Text as="p" variant="h3" sx={styles.body}>Der er alt for mange steder man kan få dårlig informationer om dæk og fælge. Det er også produkter der er svære at forstå og kræver hjælp til at finde det rigtige. Det vil jeg gerne forbedre. Derfor har jeg lavet siden her.</Text>

            <Text as="p" variant="h3" sx={styles.body}>Siden er delt op i to dele, dels information om produkter og tilbehørs produkter som ventiler, hjulkapsler, centreringsringe osv. Og dels en produktsøger hvor du kan finde de produkter som passer på din bil.</Text>

            <Heading as="h2" variant="h2">Målgruppe</Heading>
            <Text as="p" variant="h3" sx={styles.body}>Den primære målgruppe er de danske billister der mangler enten nye dæk eller fælge.</Text>

            <Text as="p" variant="h3" sx={styles.body}>Bilister som ikke nødvendigvis er eksperter i produkterne og gerne vil være sikre på at det de køber passer på deres biler.</Text>

            <Heading as="h2" variant="h2">Udgivelses Frekvens</Heading>
            <Text as="p" variant="h3" sx={styles.body}>Kvalitets indhold tager den tid kvalitets indhold tager. Jeg vil hellere udgive høj kvalitet sjældent end gå på kompromis og udgive oftere.</Text>

            <Heading as="h2" variant="h2">Hvem Står Bag?</Heading>
            <Text as="p" variant="h3" sx={styles.body}>Siden drives af mig (Frederik Banke) jeg har arbejdet med dæk og fælge branchen i over 15 år. Det er den viden og de erfaringer jeg har gjort mig som danner grundlag for guides og artikler på siden.</Text>

            <Text as="p" variant="h3" sx={styles.body}>Og så får jeg hjælp af en række dygtige folk indenfor branchen til at skrive artikler og hjælpe med tenikken.</Text>

            <Text as="p" variant="h3" sx={styles.body}>Det research og tid jeg har lagt i at finde de bedste produkter vil jeg gerne dele med andre, så derfor siden her.</Text>
          </CardComponent>
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAbout
